import { Component, Input } from '@angular/core';
import { Helpers } from 'src/app/core/helpers';

@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrl: './accordion.component.less'
})
export class AccordionComponent {
    @Input() accordionItems: { title: string }[] = [];
    openIndex: number | null = null;
    Helper = Helpers
    toggleAccordion(index: number): void {
        this.openIndex = this.openIndex === index ? null : index;

    }
    isOpen(index: number): boolean {
        return this.openIndex === index;
    }



}
