import { Component } from '@angular/core';
import { Helpers } from 'src/app/core/helpers';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrl: './footer.component.less'
})
export class FooterComponent {
	Helper = Helpers;
	version = environment.version;
	year = new Date().getFullYear();
	OSSelected:string = localStorage.getItem('OSSelected')!;
}
