import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AngularDeviceInformationService } from 'angular-device-information';
import { catchError } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { Device } from 'src/app/core/models/device.enum';

@Component({
    selector: 'app-audio-download',
    templateUrl: './audio-download.component.html',
    styles: []
})
export class AudioDownloadComponent implements OnInit {
    //https://speechgen.io/it/ Elsa (it), Bella(en), Alvaro(es), Carolie (fr), Bernd (de), Ahmed (ar), Francisca (pt)
    @Input() deviceOs?: string;
    Helper = Helpers
    device = Device;
    _currentRoute: string = '';
    public audioSrc: string = '';
    _language = Helpers.getLanguage();

    ngOnInit(): void {
        this.deviceOs = this.route.snapshot.params['deviceOs'] || this.deviceOs;
        if (!this.deviceOs) {
            this.deviceOs = Helpers.getDeviceOs(this.deviceInformationService.getDeviceInfo()?.os || '');
        }
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this._currentRoute = event.urlAfterRedirects.split('/')[1];
            }
        });

        let audioPath = `assets/audio/${this.deviceOs}/${this._language}/download.mp3`;
        fetch(audioPath, { method: 'HEAD' })
            .then(response => {
                if (response.ok) {
                    this.audioSrc = audioPath;
                } else {
                    this.audioSrc = '';
                }
            })
            .catch(error => {
                this.audioSrc = '';
            });
    }

    constructor(
        private route: ActivatedRoute,
        private deviceInformationService: AngularDeviceInformationService,
        private _router: Router
    ) { }
}