@if(deviceOs){
    <section>
        <div>
            <div class="relative">
                <video class="w-full object-cover" [src]="videoSrc" autoplay loop muted controls ></video>
                <div class="relative text-white text-[1.5rem] text-center" [ngClass]="{'text-right': Helper.getLanguage() === 'ar', 'unicode-bidi':Helper.getLanguage() === 'ar', 'text-left': Helper.getLanguage() !== 'ar'}">
                    @switch (deviceOs) {
                        @case ("macos") {
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.mac.install_step1' | translate"></span><br>
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.mac.install_step2' | translate"></span><br>
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.mac.install_step3' | translate"></span><br>
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.mac.install_step4' | translate"></span><br>
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.mac.install_step5' | translate"></span><br>
                        }
                        @case ("ios") {
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.ios.install_step1' | translate"></span><br>
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.ios.install_step2' | translate"></span><br>
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.ios.install_step3' | translate"></span><br>
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.ios.install_step4' | translate"></span><br>
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.ios.install_step5' | translate"></span><br>
                        }
                        @case ("windows") {
                            <!-- <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.windows.welcome_step1' | translate"></span> -->
                        }
                        @case ("android") {
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.android.install_step1' | translate"></span><br>
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.android.install_step2' | translate"></span>
                        }
                    }
                </div>
              </div>
        </div>
        <div class="mt-5">
			<div class="text-center mt-8">
				<button class="w-[90%] h-14 mt-6 inline-block bg-yellow-400 rounded-xl text-white py-2 px-4 shadow hover:bg-blue-700 focus:bg-blue-700 hover:text-white focus:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 cursor-pointer disabled:bg-gray-300 disabled:text-black disabled:cursor-not-allowed" [routerLink]="['/home']"> {{'landing.connect_btn' | translate}}</button>
			</div>
		</div>
    </section>
}