<footer class="bg-footer-background text-white py-4 font-bold" [ngClass]="{'text-right': Helper.getLanguage() === 'ar', 'unicode-bidi':Helper.getLanguage() === 'ar', 'text-left': Helper.getLanguage() !== 'ar'}">
	<div class="max-w-4xl mx-auto flex"  >
		<ul class="flex flex-col justify-around">
			<li class="py-2">
				<a [routerLink]="['/faqs']" class="text-white font-source_extralight no-underline hover:text-gray-400">{{'footer.faq' | translate}}</a>
			</li>
			<li class="py-2">
				<a [routerLink]="['how-to/',OSSelected]" class="text-white font-source_extralight no-underline hover:text-gray-400">{{'footer.instruction' | translate}}</a>
			</li>
		</ul>
	</div>
	<hr class="my-4">
	<div class="container mx-auto text-center">
		<span class="text-white font-source_extralight" [innerHTML]="'footer.privacyPolicy' | translate"></span> |
		<span class="text-white font-source_extralight" [innerHTML]="'footer.termsAndConditions' | translate"></span>
		<div class="flex justify-center mt-4">
			<span class="text-white font-source_extralight">powered by</span>
			<img src="./assets/images/logo_white.png" alt="guglielmo_white_logo" class="w-[100px] ml-2">
		</div>
		<h6 class="text-sm mt-4">
			<span class="sr-only">Version:</span> v{{version}} - <span class="sr-only">Copyright:</span> &copy;{{ year }}
		</h6>
	</div>
</footer>