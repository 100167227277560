<header class="border-b bg-white border-gradient-to-right from-indigo-500 to-purple-600 shadow-lg p-4">
	<div class="container mx-auto flex flex-col sm:flex-row items-center justify-between sm:justify-between">
		<!-- Logo -->
		<div class="mb-2 sm:mb-0">
			<a href="/">
				<img src="/assets/images/logo.png" alt="City of Westminster Logo" class="w-[220px]">
			</a>
		</div>
		<div>
			<nav>
				<ul>
					<li><app-language-selector></app-language-selector></li>
				</ul>
			</nav>
		</div>
	</div>
</header>