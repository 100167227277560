@if(deviceOs){
    <section>
        <div>
            <div class="relative">
                <video class="w-full object-cover" [src]="videoSrc" autoplay loop muted controls ></video>
                <div class="relative text-white text-[1.5rem] text-center" [ngClass]="{'text-right': Helper.getLanguage() === 'ar', 'unicode-bidi':Helper.getLanguage() === 'ar', 'text-left': Helper.getLanguage() !== 'ar'}">
                    @switch (deviceOs) {
                        @case ("macos") {
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.mac.welcome_step1' | translate"></span>
                        }
                        @case ("ios") {
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.ios.welcome_step1' | translate"></span><br>
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.ios.welcome_step2' | translate"></span><br>
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.ios.welcome_step3' | translate"></span>
                        }
                        @case ("windows") {
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.windows.welcome_step1' | translate"></span>
                        }
                        @case ("android") {
                            <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.android.welcome_step1' | translate"></span>
                        }
                    }
                </div>
              </div>
        </div>
    </section>
}