@if(deviceOs){
    <section>
        <div>
            <div class="relative text-center">
                @if(audioSrc){
                    <audio controls class="m-auto border border-gray-300 rounded-lg">
                        <source [src]="audioSrc" type="audio/mp3">
                    </audio>
                }@else{
                    <p>Audio file not available</p>
                }
            </div>
        </div>
    </section>
}