@if(deviceOs){
    <section>
        <div>
            <div class="relative text-center">
                @if(audioSrc){
                    <audio controls class="m-auto border border-gray-300 rounded-lg">
                        <source [src]="audioSrc" type="audio/mp3">
                    </audio>
                }@else{
                    <p>Audio file not available</p>
                }
            </div>
        </div>
        <div class="mt-5">
			<div class="text-center mt-8">
				<button class="w-[90%] h-14 mt-6 inline-block bg-yellow-400 rounded-xl text-white py-2 px-4 shadow hover:bg-blue-700 focus:bg-blue-700 hover:text-white focus:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 cursor-pointer disabled:bg-gray-300 disabled:text-black disabled:cursor-not-allowed" [routerLink]="['/home']"> {{'landing.connect_btn' | translate}}</button>
			</div>
		</div>
    </section>
}