import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularDeviceInformationService } from 'angular-device-information';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { Device } from 'src/app/core/models/device.enum';
import { CheckBrowserService } from 'src/app/core/services/internal/check_browser.service';

@Component({
    selector: 'app-install-instructions',
    templateUrl: './install-instructions.component.html',
    styleUrl: './install-instructions.component.less'
})
export class InstallInstructionsComponent {
    private _subscriptions: Array<Subscription> = [];
    @Input() deviceOs?: string;
    _showInfo:boolean = false;
    Helper = Helpers
    device = Device;

    constructor(
        private route: ActivatedRoute,
        private deviceInformationService: AngularDeviceInformationService,
        private _checkBrowserService: CheckBrowserService) { }

    ngOnInit(): void {
        this.deviceOs = this.route.snapshot.params['deviceOs'] || this.deviceOs;

        if (!this.deviceOs) {
            this.deviceOs = Helpers.getDeviceOs(this.deviceInformationService.getDeviceInfo()?.os || '');
        }
        this._showInfo = this.checkIfIsAndroidChrome() || this.checkIfIsIosSafari();
    }

    /**
     * Checks if the current device is a mobile device.
     *
     * This method subscribes to the `isMobile` observable from the `_checkBrowserService`
     * to determine if the current device is a mobile device. It returns a boolean value
     * indicating the result.
     *
     * @returns {boolean} - `true` if the current device is a mobile device, `false` otherwise.
     */
    checkIfIsMobile(): boolean {
        let isMobileResult = false;
        this._subscriptions.push(this._checkBrowserService.isMobile.subscribe({
            next: (isMobile) => {
                if(isMobile){
                    if(this.checkIfIsAndroidChrome() || this.checkIfIsIosSafari()){
                        this._showInfo = true;
                    }else{
                        this._showInfo = false;
                    }
                }else{
                    this._showInfo = true
                }
            },
            error: (error) => {
                console.error('Error checking if mobile.', error);
                isMobileResult = false;
            }
        }));
        return isMobileResult;
    }

    /**
     * Checks if the browser is Android Chrome.
     *
     * This method subscribes to the `isAndroidChrome` observable from the `_checkBrowserService`
     * and sets the result to `isAndroidChromeResult`. If an error occurs during the subscription,
     * it logs the error and sets `isAndroidChromeResult` to false.
     *
     * @returns {boolean} - Returns `true` if the browser is Android Chrome, otherwise `false`.
     */
    checkIfIsAndroidChrome(): boolean {
        let isAndroidChromeResult = false;
        this._subscriptions.push(this._checkBrowserService.isAndroidChrome.subscribe({
            next: (isAndroidChrome) => {
                isAndroidChromeResult = isAndroidChrome;
            },
            error: (error) => {
                console.error('Error checking if Android Chrome.', error);
                isAndroidChromeResult = false;
            }
        }));
        return isAndroidChromeResult;
    }

    /**
     * Checks if the current browser is Safari on an iOS device.
     *
     * This method subscribes to the `isIos` observable from the `_checkBrowserService`
     * to determine if the current browser is Safari on an iOS device. It updates the
     * `isIosResult` variable based on the emitted value from the observable.
     *
     * @returns {boolean} - Returns `true` if the current browser is Safari on an iOS device, otherwise `false`.
     */
    checkIfIsIosSafari(): boolean {
        let isIosResult = false;
        this._subscriptions.push(this._checkBrowserService.isIos.subscribe({
            next: (isIos) => {
                isIosResult = isIos;
            },
            error: (error) => {
                console.error('Error checking if iOS.', error);
                isIosResult = false;
            }
        }));
        return isIosResult;
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(b => b.unsubscribe());
    }
}
