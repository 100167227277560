import { Injectable } from '@angular/core';
import { AngularDeviceInformationService } from 'angular-device-information';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class CheckBrowserService {

    private _isMobile = new BehaviorSubject<boolean>(false);
    isMobile = this._isMobile.asObservable();

    private _isAndroidChrome = new BehaviorSubject<boolean>(false);
    isAndroidChrome = this._isAndroidChrome.asObservable();

    private _isIosSafari = new BehaviorSubject<boolean>(false);
    isIos = this._isIosSafari.asObservable();

    constructor(
        private _deviceInformationService: AngularDeviceInformationService,
    ) {
        this.checkIfMobile();
        this.checkIfAndroidChrome();
        this.checkIfIosSafari();
    }

    checkIfMobile() {
        const isMobile  = this._deviceInformationService.isMobile();
        this._isMobile.next(isMobile);
    }

    checkIfAndroidChrome() {
        const { os, browser } = this._deviceInformationService.getDeviceInfo();
        this._isAndroidChrome.next(os === 'Android' && browser === 'Chrome');
    }

    checkIfIosSafari() {
        const { os, browser } = this._deviceInformationService.getDeviceInfo();
        this._isIosSafari.next(os === 'iOS' && browser === 'Safari');
    }
}