@if (deviceOs) {
	<section>
		<div class="flex flex-col gap-8" [ngClass]="{'text-right': Helper.getLanguage() === 'ar', 'unicode-bidi':Helper.getLanguage() === 'ar', 'text-left': Helper.getLanguage() !== 'ar'}">
			<!-- <h1 class=" text-center text-2xl font-source_extralight text-text-blue"> {{'instructions.title' | translate}}</h1> -->
			@switch (deviceOs) {
				@case (device.iOS) {
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">1.</span>
							</div>
							<div class="col-span-11">
								@if(_currentRoute == 'how-to'){
									<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.ios.welcome_step1_how_to' | translate"></span>
								}@else {
									<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.ios.welcome_step1' | translate"></span>
								}
								<!-- <span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.ios.welcome_step1' | translate"></span> -->
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/welcome/ios/welcome_step1.png" alt="">
						</div>
					</div>
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">2.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.ios.welcome_step2' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/welcome/ios/welcome_step2.png" alt="">
						</div>
					</div>
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">3.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.ios.welcome_step3' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/welcome/ios/welcome_step3.png" alt="">
						</div>
					</div>
				}
				@case (device.Android) {
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">1.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.android.welcome_step1' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/welcome/android/welcome_step1.png" alt="">
						</div>
					</div>
				}
				@case (device.MacOS) {
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">1.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.mac.welcome_step1_how_to' | translate"></span>
								<!-- @if(_currentRoute == 'how-to'){
								}@else {
									<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.mac.welcome_step1_how_to' | translate"></span>
								} -->
							</div>
						</div>
						<!-- <div class="m-auto w-48">
							<img src="./assets/images/instructions/welcome/mac/welcome_step1.png" alt="">
						</div> -->
					</div>
				}
				@case (device.Windows) {
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">1.</span>
							</div>
							<div class="col-span-11">
								@if(_currentRoute == 'how-to'){
									<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.windows.welcome_step1_how_to' | translate"></span>
								}@else {
									<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.windows.welcome_step1' | translate"></span>
								}
							</div>
						</div>
					</div>
				}
				@default {
					<div class="bg-white border border-gray-300 shadow-md overflow-hidden">
						<div class="p-6">
							<h3 class="text-2xl font-semibold mb-2"></h3>
							<p class="text-gray-700"></p>
						</div>
					</div>
				}
			}
		</div>
		<!-- <div class="mt-5">
			<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'instructions.ios.welcome_step4' | translate"></span>
		</div> -->
	</section>
}