import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LanguageService } from 'src/app/core/services/internal/language.service';
import { TranslationService } from 'src/app/core/services/internal/translation.service';

interface Language {
	code: string;
	name: string;
}

@Component({
	selector: 'app-language-selector',
	templateUrl: './language-selector.component.html',
	styleUrls: ['./language-selector.component.less']
})
export class LanguageSelectorComponent implements OnInit {
	languages: Language[] = [
		{ code: 'de', name: 'Deutsch' },
		{ code: 'en', name: 'English' },
		{ code: 'es', name: 'Español' },
		{ code: 'fr', name: 'Français' },
		{ code: 'it', name: 'Italiano' },
		{ code: 'pt', name: 'Português' },
		{ code: 'ar', name: 'العربية' },

	];

	selectedLang = 'en';

	constructor(
		private translationService: TranslationService,
		private router: Router,
		private languageService: LanguageService
	) {}

	ngOnInit() {
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe(() => {
				this.setSelectedLanguage();
			});
	}

	changeLang(lang: string) {
		this.selectedLang = lang;
		this.translationService.setLanguage(lang);
		// Emit a language change event
		this.languageService.update(lang);
	}

	setSelectedLanguage() {
		this.changeLang(this.translationService.getSelectedLanguage());
	}
}
