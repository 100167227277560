export const locale = {
	lang: 'en',
	data: {
		languageSelector: {
			selectLanguage: 'Select Language'
		},
		accessibility: {
			resetTitle: 'reset',
			closeTitle: 'close',
			menuTitle: 'Accessibility Options',
			increaseText: 'increase text size',
			decreaseText: 'decrease text size',
			increaseTextSpacing: 'increase text spacing',
			decreaseTextSpacing: 'decrease text spacing',
			increaseLineHeight: 'increase line height',
			decreaseLineHeight: 'decrease line height',
			invertColors: 'invert colors',
			grayHues: 'gray hues',
			underlineLinks: 'underline links',
			bigCursor: 'big cursor',
			readingGuide: 'reading guide',
			textToSpeech: 'text to speech',
			speechToText: 'speech to text',
			disableAnimations: 'disable animations',
			hotkeyPrefix: 'Hotkey:'
		},
		pageNotFound: {
			title: 'Page Not Found',
			subtitle: 'Sorry, but the page you were trying to view does not exist.',
			action: 'Go to homepage'
		},
		error: {
			title: 'Error',
			subtitle: 'Sorry, but something went wrong.',
			action: 'Go to homepage',
			messages: {
				generic: 'An error occurred while processing your request. Please try again later.',
				browser: `<span class="text-xl font-source_extralight text-text-blue">Dear user, <br>the browser you are using is not supported. To ensure an optimal experience, we recommend switching to </span>`,
				thanks: `Thank you!`
			}
		},
		home: {
			title: 'Welcome',
			downloadProfile: 'Download Your Account Wi-Fi Profile',
			email: {
				description: "If you want, you can provide your email address to receive all the instructions.",
				placeholder: "example@domain.com",
			},
			privacy: {
				one: 'I have read and understood',
				two: 'the Privacy Policy',
				three: 'and the',
				four: 'Terms and Conditions of the service',
				total: `I have read and understood <a class='font-source_regular text-text-blue' href="https://wballiance.com/openroaming/privacy-policy/" target="_blank" data-extlink rel="noreferrer">the Privacy Policy</a> and the <a class='font-source_regular text-text-blue' href="https://wballiance.com/openroaming/toc/" target="_blank" data-extlink rel="noreferrer">Terms and Conditions of the service</a>`
			},
			requiredFields: "Required fields are marked with an asterisk (*)",
			error: {
				emailFormat: "Invalid email format",
			}
		},
		instructions: {
			title: "Connect your device",
			checkOS: "Your Operating System is: ",
			changeOS: "Is this not correct? You can change it from here.",
			noOs: "Could not identify your Operating System",
			ios: {
				welcome_step1: `Click on the <span class='font-source_regular text-text-blue'>"Download Your Account Wi-Fi Profile"</span> button below to download your Wi-Fi Profile and click <span class='font-source_regular text-text-blue'>"Continue"</span> on the window that appears`,
				welcome_step2: `Click <span class='font-source_regular text-text-blue'>"Allow"</span> on the screen that appears`,
				welcome_step3: `Click <span class='font-source_regular text-text-blue'>"Close"</span> on the screen that appears`,
				welcome_step4: `Once the download is complete, you will be redirected to the page with instructions to install your Wi-Fi Profile.`,
				welcome_step1_how_to: `To download your Wi-Fi profile, click the <span class='font-source_regular text-text-blue'>"Download your Wi-Fi profile"</span> button at the bottom of the download page and press "Continue" in the window that appears afterwards.`
			},
			android: {
				welcome_step1: `To download your Wi-Fi profile, click on the <span class='font-source_regular text-text-blue'>\"Download your Wi-Fi profile\"</span> button located at the bottom of the download page and press \"Install\" in the window that follows.`
			},
			mac: {
				welcome_step1: `Click on the button below <span class='font-source_regular text-text-blue'>"Download your Wi-Fi profile"</span> to download the Wi-Fi profile`,
				welcome_step1_how_to: `To download your Wi-Fi profile, click on the <span class='font-source_regular text-text-blue'>"Download your Wi-Fi profile"</span> button located at the bottom of the download page`,
			},
			windows: {
				welcome_step1_how_to: `To download your Wi-Fi profile, click on the <span class='font-source_regular text-text-blue'>"Download your Wi-Fi profile"</span> button located at the bottom of the download page`,
				welcome_step1: `To download your Wi-Fi profile, click on the <span class='font-source_regular text-text-blue'>"Download your Wi-Fi profile"</span> button located at the bottom of the download page`,
			},
		},
		install_instructions: {
			title: "How to install your Wi-Fi Account Profile",
			ios: {
				install_step1: `Open the <span class='font-source_regular text-text-blue'>"Settings"</span> app and click on <span class='font-source_regular text-text-blue'>"Downloaded Profile"</span>`,
				install_step2: `Click on <span class='font-source_regular text-text-blue'>"Install"</span> in the top-right corner and enter your device's passcode`,
				install_step3: `Click on <span class='font-source_regular text-text-blue'>"Install"</span> in the window that appears at the bottom to confirm the installation`,
				install_step4: `After installing your Wi-Fi account profile, click on <span class='font-source_regular text-text-blue'>"Done"</span>. You will see that your profile has been successfully installed in the <span class='font-source_regular text-text-blue'>"VPN & Device Management"</span> section`,
				install_step5: `It is now possible to search for the Wi-Fi network name and access it securely`,
			},
			android: {
				install_step1: `Click on <span class='font-source_regular text-text-blue'>"Done"</span> in the window that appears below to confirm the installation`,
				install_step2: `You can now search for the Wi-Fi network name and securely connect`,
			},
			mac: {
				install_step1: `Click on the downloaded file, open the <span class='font-source_regular text-text-blue'>"Settings"</span> app, and click on <span class='font-source_regular text-text-blue'>"Privacy & Security"</span>`,
				install_step2: `Click on <span class='font-source_regular text-text-blue'>"Profile"</span>`,
				install_step3: `Double click on the profile, then click on <span class='font-source_regular text-text-blue'>"Install"</span>`,
				install_step4: `If prompted, enter your personal password and click <span class='font-source_regular text-text-blue'>"OK"</span>`,
				install_step5: `You can now search for the Wi-Fi network name and securely connect`,
			},
			windows: {
				install_step1: `Click on <span class='font-source_regular text-text-blue'>Open Settings</span> in the window that appears`,
				install_step2: `Click on <span class='font-source_regular text-text-blue'>Add</span> in the window that appears`,
				install_step3: `You are now able to search for the Wi-Fi network name and connect securely`,
			},
			icon: {
				watch: `Watch the video`,
				listen: `Listen to the instructions`,
				read: `Read the instructions`,
			}

		},
		landing: {
			text_1: 'OpenRoaming is a standard developed by the Wireless Broadband Alliance (WBA) designed to simplify the process of connecting to Wi-Fi networks by creating a seamless and secure roaming experience.',
			text_2: 'The project aims to create a uninterrupted roaming experience across the many disparate Wi-Fi networks in London.',
			text_3: 'You can connect to these Wi-Fi networks without having to repeatedly log in or enter credentials, protecting your data and privacy.',
			text_4: 'All you need to do is download a valid "Wi-Fi profile" on your device and enjoy.',
			connect_btn: 'Connect now',
			connect_howTo: 'How to',
			text_5: 'Do you need more details? Find how it works!',
		},
		how_to: {
			intro: `To connect to the Wi-Fi networks, you will need to download a 'Wi-Fi profile' onto your device and enjoy browsing.`,
			title_1: `How to download your profile`,
			title_2: `How to install your Wi-Fi profile`,
		},
		footer: {
			privacyPolicy: `<a class='font-source_regular text-white hover:text-gray-400' href="https://wballiance.com/openroaming/privacy-policy/" target="_blank" data-extlink rel="noreferrer">Privacy Policy</a>`,
			termsAndConditions: `<a class='font-source_regular text-white hover:text-gray-400' href="https://wballiance.com/openroaming/toc/" target="_blank" data-extlink rel="noreferrer">Terms and Conditions of the service</a>`,
			faq: 'FAQ',
			instruction: 'Instructions',
		},
		faq: {
			general_questions: `General Questions`,
			how_to_use_service: `How to use the service`,
			title_1: `Where can I find more information from Westminster City Council? [WCC]`,
			title_2: `What are the benefits of OpenRoaming for Westminster citizens? [WCC]`,
			title_3: `Where is OpenRoaming available in Westminster? [WCC]`,
			title_4: `Are there any data limits? [WCC]`,
			title_5: `Are there usage restrictions?  [WCC]`,
			title_6: `How to use the service`,
			subtitle_1:`<ul><li class="text-text-blue list-disc"><p style="text-align: justify;"><span class="font-source_light">WCC Website:</span><span class="font-source_extralight">&nbsp;Check their official website for details on their OpenRoaming service.</span></p></li><li class="text-text-blue list-disc"><p style="text-align: justify;"><span class="font-source_light">Contact WCC:</span><span class="font-source_extralight">&nbsp;Reach out to their customer service or digital services department for specific questions.</span></p></li></ul>`,
			subtitle_2:`<ul><li class="text-text-blue list-disc"><p style="text-align: justify;"><span class="font-source_light">Seamless connectivity:</span><span class="font-source_extralight">&nbsp;No more searching for Wi-Fi and repeatedly entering login details.</span></p></li><li class="text-text-blue list-disc"><p style="text-align: justify;"><span class="font-source_light">Improved public Wi-Fi access:</span><span class="font-source_extralight">&nbsp;More convenient access to online services and information.</span></p></li><li class="text-text-blue list-disc"><p style="text-align: justify;"><span class="font-source_light">Potential cost savings:</span><span class="font-source_extralight">&nbsp;Reduced reliance on mobile data.</span></p></li><li class="text-text-blue list-disc"><p style="text-align: justify;"><span class="font-source_light">More secure connectivity:</span><span class="font-source_extralight">&nbsp; OpenRoaming is more secure than an open network or a captive portal-based network.</span></p></li><li class="text-text-blue list-disc"><p style="text-align: justify;"><span class="font-source_light">Digital inclusion:</span><span class="font-source_extralight">&nbsp; City-wide public Wi-Fi promotes digital inclusion by providing affordable and accessible internet, fostering digital literacy, and bridging the digital divide for all residents.</span></p></li></ul>`,
			subtitle_3: `At the Pilot stage you can use the service in these venues:`,
			subtitle_4: `Currently, there are no data limits. We may evaluate this policy in the future.`,
			subtitle_5: `Any usage is allowed if it is compatible with the <a href="https://wballiance.com/openroaming/toc/"> WBA Terms & Conditions </a>and the UK Law. Due to improper use, the WCC could decide to revoke the profile.`,
			seamless_connectivity: `No more searching for Wi-Fi and repeatedly entering login details.`,
			improved_public_wifi_access: `More convenient access to online services and information.`,
			potential_cost_savings: `Reduced reliance on mobile data.`,
			more_secure_connectivity: `OpenRoaming is more secure than an open network or a captive portal-based network.`,
			digital_inclusion: `City-wide public Wi-Fi promotes digital inclusion by providing affordable and accessible internet, fostering digital literacy, and bridging the digital divide for all residents.`,
			what_is_openRoaming: `What is OpenRoaming?`,
			what_is_openRoaming_text_1: `OpenRoaming is a standard developed by the Wireless Broadband Alliance (WBA®) designed to simplify the process of connecting to Wi-Fi networks by creating a seamless and secure roaming experience.`,
			what_is_openRoaming_text_2: `The project aims to create an uninterrupted roaming experience across the many disparate Wi-Fi networks in London.`,
			what_is_openRoaming_text_3: `You can connect to these Wi-Fi networks without having to repeatedly log in or enter credentials, protecting your data and privacy. All you need to do is download a valid "Wi-Fi profile" on your device and enjoy.`,
			what_standards_does_openRoaming_use: `What standards does OpenRoaming use?`,
			what_standards_does_openRoaming_use_text: `OpenRoaming is built on the Passpoint and Hotspot 2.0 standards and leverages many authentication standards, including RADIUS, Extensible Authentication Protocol (EAP), and OAuth v2.`,
			what_is_passpoint: `What is Passpoint?`,
			what_is_passpoint_text: `OpenRoaming is based on Passpoint®, an industry solution that simplifies Wi-Fi access and eliminates the need to search for and authenticate a network every time you visit.`,
			how_secure_is_openRoaming: `How secure is OpenRoaming?`,
			how_secure_is_openRoaming_text: `OpenRoaming prioritizes security by utilizing robust authentication protocols like RadSec, EAP-TLS, EAP-TTLS, and EAP-AKA. Ensure all authentication traffic is encrypted with TLS. By leveraging WPA2-Enterprise or WPA3 over-the-air encryption, OpenRoaming networks offer enterprise-grade protection, distinguishing them from typical open wireless guest networks.`,
			does_openRoaming_respect_my_rivacy: `Does OpenRoaming respect my privacy?`,
			does_openRoaming_respect_my_rivacy_text: `Yes, OpenRoaming is designed to be able to operate with strict privacy controls. An end user can choose what information will be shared with wireless networks, if any. Please read the privacy policy to have more details: <a href="https://wballiance.com/openroaming/privacy-policy/">https://wballiance.com/openroaming/privacy-policy/</a>. `,
			which_personal_data_you_keep_to_generate_an_openRoaming_identity: `Which personal data you keep to generate an OpenRoaming identity`,
			which_personal_data_you_keep_to_generate_an_openRoaming_identity_text: `The WCC OpenRoaming Identity is generated without collecting any personal information. The only data we conserve are related to `,
			how_long_do_you_keep_my_data: `How long do you keep my data?`,
			how_long_do_you_keep_my_data_text: `Session data are kept for 6 months according to OpenRoaming policies.`,
			what_is_openRoaming_identity: `What is an OpenRoaming identity?`,
			what_is_openRoaming_identity_text: `An OpenRoaming identity is a combination of username and password bound to a certain Identity Provider (IDP). You normally do not see these username and password since they are embedded in your OpenRoaming profile.`,
			what_is_an_OpenRoaming_profile: `What is an OpenRoaming profile?`,
			what_is_an_OpenRoaming_profile_text: `An OpenRoaming profile is a special file containing information your device can use to install an OpenRoaming network. The format of the file depends on your operating systems, operating system version and device, for this reason you cannot reuse the same profile on multiple devices.`,
			is_the_service_free: `Is the service free?`,
			is_the_service_free_text: `Yes, the service is free.`,
			how_long_is_the_profile_valid: `How long is the profile valid?`,
			how_long_is_the_profile_valid_text: `Your profile never expires.`,
			i_already_have_an_OpenRoaming_profile_obtained_from_another_provider: `I already have an OpenRoaming profile obtained from another provider. Could I join the Westminster network?`,
			i_already_have_an_OpenRoaming_profile_obtained_from_another_provider_text: `Yes, you can definitely use it.`,
			could_you_have_an_OpenRoaming_WCC_profile_elsewhere: `Could you have an OpenRoaming WCC profile elsewhere?`,
			could_you_have_an_OpenRoaming_WCC_profile_elsewhere_text: `Yes, the OpenRoaming WCC profile can be used worldwide in any network federated with OpenRoaming`,
			how_can_I_leave_the_OpenRoaming_WCC_Project: `How can I leave the OpenRoaming WCC Project?`,
			how_can_I_leave_the_OpenRoaming_WCC_Project_text: `To opt out of OpenRoaming, simply remove any OpenRoaming profiles you've installed on your devices. This will stop accessing the service. Session data related to your identity will be kept 6 months after your last access.`,
			how_many_devices_can_I_use_the_service: `How many devices can I use the service?`,
			how_many_devices_can_I_use_the_service_text: `You are free to install a profile on as many devices as you want.`,
			how_do_I_transfer_OpenRoaming_to_my_new_phone: `How do I transfer OpenRoaming to my new phone?`,
			how_do_I_transfer_OpenRoaming_to_my_new_phone_text: `If the OpenRoamng profiles do not appear on your new phone, ust visit the OpenRoaming portal to download a new profile.`,
			who_can_use_OpenRoaming_in_Westminster: `Who can use OpenRoaming in Westminster?`,
			who_can_use_OpenRoaming_in_Westminster_text: `Everybody can use OpenRoaming in Westminster and everybody can get an Open Roaming profile in Westminster.`,
			how_can_the_WCC_revoke_my_profile: `How can the WCC revoke my profile?`,
			how_can_the_WCC_revoke_my_profile_text: `WCC can decide to block a profile that does not respect Terms & Conditions.`,
			how_do_I_connect_to_OpenRoaming_in_Westminster: `How do I connect to OpenRoaming in Westminster?`,
			how_do_I_connect_to_OpenRoaming_in_Westminster_text: `<ul><li class="text-text-blue list-disc"><p style="text-align: justify;"><span class="font-source_light">Ensure your device is compatible:</span><span class="font-source_extralight">&nbsp;Most newer smartphones and laptops support the necessary Wi-Fi standards. In particular, these operating systems should be compatible</span></p><ul class="ml-16"><li style="list-style-type:circle;color:#353744;font-size:11pt;font-family:Nunito,sans-serif;"><p style="text-align: justify;"><span>Windows 10+;</span></p></li><li style="list-style-type:circle;color:#353744;font-size:11pt;font-family:Nunito,sans-serif;"><p style="text-align: justify;"><span>macOS 10.9+;</span></p></li><li style="list-style-type:circle;color:#353744;font-size:11pt;font-family:Nunito,sans-serif;"><p style="text-align: justify;"><span>Android 9+;</span></p></li><li style="list-style-type:circle;color:#353744;font-size:11pt;font-family:Nunito,sans-serif;"><p style="text-align: justify;"><span>iOS 7+.</span></p></li></ul></li><li class="text-text-blue list-disc"><p style="text-align: justify;"><span class="font-source_light">Get a valid OpenRoaming profile.&nbsp;</span><span class="font-source_extralight">You need to get a valid OpenRoaming profile from the WCC website or from other identity providers.</span></p></li><li class="text-text-blue list-disc"><p style="text-align: justify;"><span class="font-source_light">Look for the OpenRoaming network:</span><span class="font-source_extralight">&nbsp;Look for any Connected London Wi-Fi networks.</span></p></li><li class="text-text-blue list-disc"><p style="text-align: justify;"><span class="font-source_light">The first time you connect:</span><span class="font-source_extralight">&nbsp;You might be directed to a simple landing page to accept terms or provide basic information. After this initial connection, your device should automatically join the network in the future.</span></p></li></ul>`,

			should_I_disable_MAC_Randomization_to_use_the_service:`Should I disable MAC Randomization to use the service?`,
			should_I_disable_MAC_Randomization_to_use_the_service_text:`No, OpenRoaming has not been affected by MAC randomization.`,
			how_can_I_install_the_profile_on_my_iPhone:`How can I install the profile on my iPhone?`,
			how_can_I_install_the_profile_on_my_iPhone_text:`Please refer to this guide: <a href="https://westminster.guglielmo.dev/how-to/ios">https://westminster.guglielmo.dev/how-to/ios</a>.`,
			how_can_I_install_the_profile_on_my_Android_device:`How can I install the profile on my Android device?`,
			how_can_I_install_the_profile_on_my_Android_device_text:`Please refer to this guide:<a href="https://westminster.guglielmo.dev/how-to/android"> https://westminster.guglielmo.dev/how-to/android</a>.`,
			how_can_I_install_the_profile_on_my_MacBook:`How can I install the profile on my MacBook?`,
			how_can_I_install_the_profile_on_my_MacBook_text:`Please refer to this guide:<a href="https://westminster.guglielmo.dev/how-to/macos"> https://westminster.guglielmo.dev/how-to/macos.</a>`,
			how_can_I_install_the_profile_on_my_Windows_laptop:`How can I install the profile on my Windows laptop?`,
			how_can_I_install_the_profile_on_my_Windows_laptop_text:`Please refer to this guide:<a href="https://westminster.guglielmo.dev/how-to/windows">https://westminster.guglielmo.dev/how-to/windows</a>.`,
			how_can_I_install_the_profile_on_my_Chromebook:`How can I install the profile on my Chromebook?`,
			how_can_I_install_the_profile_on_my_Chromebook_text:`We are working on it.`,
		},
	}
}