@if (deviceOs) {
	<section>
		<div class="flex flex-col gap-8" [ngClass]="{'text-right': Helper.getLanguage() === 'ar', 'unicode-bidi':Helper.getLanguage() === 'ar', 'text-left': Helper.getLanguage() !== 'ar'}">
			<!-- <h1 class=" text-center text-2xl font-source_extralight text-text-blue"> {{'install_instructions.title' | translate}}</h1> -->
			@switch (deviceOs) {
				@case (device.iOS) {
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">1.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.ios.install_step1' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/install/ios/install_step1.png" alt="">
						</div>
					</div>
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">2.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.ios.install_step2' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/install/ios/install_step2.png" alt="">
						</div>
					</div>
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">3.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.ios.install_step3' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/install/ios/install_step3.png" alt="">
						</div>
					</div>
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">4.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.ios.install_step4' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/install/ios/install_step4.png" alt="">
						</div>
					</div>
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">5.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.ios.install_step5' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<!-- <img src="./assets/images/instructions/install/install_step4.png" alt=""> -->
						</div>
					</div>
				}
				@case (device.Android) {
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">1.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.android.install_step1' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/install/android/install_step1.png" alt="">
						</div>
					</div>
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">2.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.android.install_step2' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<!-- <img src="./assets/images/instructions/install/install_step4.png" alt=""> -->
						</div>
					</div>
				}
				@case (device.MacOS) {
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">1.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.mac.install_step1' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/install/mac/install_step1.png" alt="">
						</div>
					</div>
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">2.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.mac.install_step2' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/install/mac/install_step2.png" alt="">
						</div>
					</div>
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">3.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.mac.install_step3' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/install/mac/install_step3.png" alt="">
						</div>
					</div>
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">4.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.mac.install_step4' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/install/mac/install_step4.png" alt="">
						</div>
					</div>
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">5.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.mac.install_step5' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/install/mac/install_step5.png" alt="">
						</div>
					</div>
				}
				@case (device.Windows) {
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">1.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.windows.install_step1' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/install/windows/install_step1.png" alt="">
						</div>
					</div>
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">2.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.windows.install_step2' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/images/instructions/install/windows/install_step2.png" alt="">
						</div>
					</div>
					<div>
						<div class="flex items-center g-20"  >
							<div>
								<span class="text-4xl text-gray-300">3.</span>
							</div>
							<div class="col-span-11">
								<span class="text-xl font-source_extralight text-text-blue" [innerHTML]="'install_instructions.windows.install_step3' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-[32rem]">
							<!-- <img src="./assets/images/instructions/install/windows/install_step3.png" alt=""> -->
						</div>
					</div>
				}
				@default {
					<!-- Card 1 -->
					<div class="bg-white border border-gray-300 shadow-md overflow-hidden">
						<div class="p-6">
							<h3 class="text-2xl font-semibold mb-2">Titolo Card 1 default</h3>
							<p class="text-gray-700">Descrizione dettagliata della card 1. Questa descrizione spiega il contenuto
								della card in modo chiaro e conciso.</p>
						</div>
					</div>
					<!-- Card 2 -->
					<div class="bg-white border border-gray-300 shadow-md overflow-hidden">
						<div class="p-6">
							<h3 class="text-2xl font-semibold mb-2">Titolo Card 2 default</h3>
							<p class="text-gray-700">Descrizione dettagliata della card 2. Questa descrizione spiega il contenuto
								della card in modo chiaro e conciso.</p>
						</div>
					</div>
					<!-- Card 3 -->
					<div class="bg-white border border-gray-300 shadow-md overflow-hidden">
						<div class="p-6">
							<h3 class="text-2xl font-semibold mb-2">Titolo Card 3 default</h3>
							<p class="text-gray-700">Descrizione dettagliata della card 3. Questa descrizione spiega il contenuto
								della card in modo chiaro e conciso.</p>
						</div>
					</div>
				}
			}
		</div>
		@if(_showInfo){
			<div class="mt-5">
				<div class="text-center mt-8">
					<button class="w-[90%] h-14 mt-6 inline-block bg-yellow-400 rounded-xl text-white py-2 px-4 shadow hover:bg-blue-700 focus:bg-blue-700 hover:text-white focus:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 cursor-pointer disabled:bg-gray-300 disabled:text-black disabled:cursor-not-allowed" [routerLink]="['/home']"> {{'landing.connect_btn' | translate}}</button>
				</div>
			</div>
		}
	</section>
}