import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { HeaderComponent } from './header/header.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AccessibilityComponent } from './accessibility/accessibility.component';
import { FooterComponent } from './footer/footer.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CardComponent } from './card/card.component';
import { InstructionsComponent } from './instructions/instructions.component';
import { RouterModule } from '@angular/router';
import { AccordionComponent } from './accordion/accordion.component';
import { InstallInstructionsComponent } from './install-instructions/install-instructions.component';
import { VideoDownloadComponent } from './video-download/video-download.component';
import { AudioDownloadComponent } from './audio-download/audio-download.component';
import { VideoInstallComponent } from './video-install/video-install.component';
import { AudioInstallComponent } from './audio-install/audio-install.component';



@NgModule({
	declarations: [
		LanguageSelectorComponent,
		HeaderComponent,
		AccessibilityComponent,
		FooterComponent,
		CheckboxComponent,
		CardComponent,
		InstructionsComponent,
		AccordionComponent,
		InstallInstructionsComponent,
		VideoDownloadComponent,
		AudioDownloadComponent,
		VideoInstallComponent,
		AudioInstallComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		TranslateModule,
		RouterModule
	],
	exports: [
		LanguageSelectorComponent,
		HeaderComponent,
		AccessibilityComponent,
		FooterComponent,
		CheckboxComponent,
		CardComponent,
		InstructionsComponent,
		AccordionComponent,
		InstallInstructionsComponent,
		VideoDownloadComponent,
		AudioDownloadComponent,
		VideoInstallComponent,
		AudioInstallComponent,
	]
})
export class LayoutModule { }
